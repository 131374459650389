const rowPerPageOptions = [{ text: '10', id: 10 }, { text: '25', id: 25 }, { text: '50', id: 50 }, { text: '100', id: 100 }]
const rowPerPagePopupOptions = [{ text: '10', id: 10 }, { text: '15', id: 15 }, { text: '20', id: 20 }, { text: '25', id: 25 }]
const pageSize = 25;
let now = new Date();
let day = now.getDate() < 10 ? '0' + now.getDate() : now.getDate();
const dateNow = day + '-' + parseInt(now.getMonth() + 1) + '-' + now.getFullYear();
const yearNow = now.getFullYear();
const monthNow = parseInt(now.getMonth() + 1);
let windowWidth = window.innerWidth;
const assetsUrl = "https://assets.ppl.com.vn/"

const monthOfYear = [ 
    { id: 1, text: 'Tháng 01' },
    { id: 2, text: 'Tháng 02' },
    { id: 3, text: 'Tháng 03' },
    { id: 4, text: 'Tháng 04' },
    { id: 5, text: 'Tháng 05' },
    { id: 6, text: 'Tháng 06' },
    { id: 7, text: 'Tháng 07' },
    { id: 8, text: 'Tháng 08' },
    { id: 9, text: 'Tháng 09' },
    { id: 10, text: 'Tháng 10' },
    { id: 11, text: 'Tháng 11' },
    { id: 12, text: 'Tháng 12' }
]

const typeFee = [ 
    { value: 1, text: 'Chi phí' }, 
    { value: 2, text: 'Chi hộ' },  
    { value: 3, text: 'Phí dịch vụ' },  
    { value: 4, text: 'Lương lái xe' },
    { value: 5, text: 'Cước vận chuyển' },
    { value: 6, text: 'VETC' },
    { value: 7, text: 'Cược cont' },
    { value: 8, text: 'Cược sửa chữa' }
]

const typeFeeHeavy = [ 
    { value: 1, text: 'Chi phí' }, 
    { value: 2, text: 'Chi hộ' },  
    { value: 3, text: 'Phí dịch vụ' },  
    { value: 4, text: 'Lương chuyến' },
    { value: 5, text: 'Cước vận chuyển' },
    { value: 6, text: 'VETC' },
    { value: 7, text: 'Cược cont' },
    { value: 8, text: 'Cược sửa chữa' },
    { value: 9, text: 'Lương cố định' },
    { value: 10, text: 'Chi phí khấu hao' },
    { value: 11, text: 'Chi phí cố định' },
]

const revenueType = [ 
    { value: 1, text: 'Phải trả làm hàng' }, 
    { value: 2, text: 'Phải trả vận chuyển' },  
    { value: 3, text: 'Phải trả vendor' },  
    { value: 4, text: 'Lương lái xe' },
    { value: 5, text: 'Cước vận chuyển' } 
]

const paymentMethod = [ { value: 1, text: 'Chuyển khoản' }, { value: 2, text: 'Tiền mặt' } ]
const approveTypes = [ { value: false, text: 'Phê duyệt' }, { value: true, text: 'Theo dõi - góp ý' } ]

const typeFeeOnBehalf = [ 
    { value: 2, text: 'Chi hộ' },  
    { value: 3, text: 'Phí dịch vụ' }
]

const numberF = {   
    decimal: '.',
    separator: ',',
    suffix: '',
    precision: 0,
    masked: false,
    nullValue: ''
}

const vatF = {   
    decimal: '.',
    separator: ',',
    suffix: ' %',
    precision: 4,
    masked: false,
    nullValue: ''
}

const currencyF = {   
    decimal: '.',
    separator: ',',
    suffix: ' VNĐ',
    precision: 0,
    masked: false,
    nullValue: ''
}

const currencyF2 = {   
    decimal: '.',
    separator: ',',
    suffix: '',
    precision: 0,
    masked: false,
    nullValue: ''
}

function isNumeric(value){
    return /^-?\d+$/.test(value);
}

function calCurrency(quantity, price, vat) {
    let amount = quantity * price;
    let amountVat = (vat/100) * amount;
    return amount + amountVat;
}

function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min; 
}

function getFakeId(){
    return getRandomInt(100, 99999);
}

function formatNum(value) {
    let val = (value/1).toFixed(2).replace(',', '')
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}

var categoryFee = {
    customs: 1,
    revenue: 8,
    port: 3,
    job: 4,
    service: 11,
    booking: 2,
}

var groupCode = {
    product : 1,
    staff : 2,
    customer: 3,
    vehicleStatus: 4,
    insurance: 5,
    project: 6,
    contract: 7,
    tire: 8,
    location: 9,
    area: 10,
    repairSourceProduct: 11,
    repairLevel: 12, //Cấp sửa chữa
    approveRepair: 13,
    repairType: 14,  //Đề nghị: nội bộ sửa chữa/thuê ngoài
    requestRepairType: 15,  //Loại : sửa chữa, bảo dưỡng, cấp vật tư,
    typeGoods: 16, // Loại hàng hóa
    jobStatus: 17, //Trạng thái lệnh điều xe
    routeStatus: 18, //Trạng thái hành trình
    repairProductUnit: 19, //Đơn vị vật tư sửa chữa
    customsType: 20, //Đơn vị vật tư sửa chữa
}

var approveType = {
    requestRepair: 1,
    repairEstimate: 2,
    repairAdvancePayment: 3,
    repairInvoice: 4,
    customsEstimate: 5,
    customsAdvancePayment: 6,
    customsInvoice: 7,
    jobEstimate: 8,
    jobAdvancePayment: 9,
    jobInvoice: 10,
}

var companyType = {
    bank : 1,
    insurance: 2,
    supplier: 3,
    repair: 4,
    invoice: 5,
    contractor: 6,
    customer: 7,
    owner: 8,
    shipping: 9,
    transport: 10,
    customs: 11,
    port: 12  //Cảng
}

var productType = {
    tire: '1'
}


var categoryType = {
    customer: 1,
    fee: 2,
    feeHeavy: 3
}

const typeOfTransportHeavy = 
[ 
    { id: 1, text: 'Hàng xuất' }, 
    { id: 2, text: 'Hàng nhập' },
    { id: 3, text: 'Hàng door-door' }
]

const typeOfTransport = 
[ 
    { id: 1, text: 'Hàng xuất' }, 
    { id: 2, text: 'Hàng nhập' },
    { id: 3, text: 'Hàng door-door' },
    { id: 4, text: 'Hàng chuyển cảng' },
    { id: 5, text: 'Hàng chuyển kho' },
    { id: 6, text: 'Chuyển rỗng' } 
]

const planOfTransport = 
[ 
    { id: 1, text: 'Vận chuyển hàng container' }, 
    { id: 2, text: 'Vận chuyển hàng lẻ/rời' }
]

const requireOfTransport = 
[ 
    { id: 1, text: 'Nội địa' }, 
    { id: 2, text: 'Quốc tế' }
]


const goodsTypeList = 
[ 
    { id: 1, text: 'Hàng cont' }, 
    { id: 2, text: 'Hàng lẻ' },
    { id: 3, text: 'Hàng rời' },
    { id: 4, text: 'Hàng air' }
]

const goodsTypeHevayList = 
[ 
    { id: 5, text: 'Hàng STST' }, 
    { id: 1, text: 'Hàng cont' }, 
    { id: 3, text: 'Hàng rời' }
]

const paymentType = 
[ 
    { id: 1, text: 'Tạm ứng' }, 
    { id: 2, text: 'Thanh toán đối tác' },
    { id: 3, text: 'Thanh toán nhân viên' }
]

const routeType = 
[ 
    { id: 1, text: 'Kho hàng' },
    { id: 2, text: 'Cảng' },
    { id: 3, text: 'Depot/ICD' },
]

const planHeavyList = 
[ 
    { id: 1, text: 'Đường bộ' }, 
    { id: 2, text: 'Đường sắt' },
    { id: 3, text: 'Đường hàng không' },
    { id: 4, text: 'Đường biển' },
    { id: 5, text: 'Đường thủy' }
]

export default {
    planHeavyList,
    routeType,
    goodsTypeHevayList,
    typeOfTransportHeavy,
    paymentType,
    goodsTypeList,
    planOfTransport,
    requireOfTransport,
    rowPerPageOptions,
    rowPerPagePopupOptions,
    pageSize,
    isNumeric,
    getFakeId,
    groupCode,
    companyType,
    productType,
    approveType,
    dateNow,
    windowWidth,
    calCurrency,
    assetsUrl,
    formatNum,
    typeFee,
    categoryFee,
    vatF,
    currencyF,
    currencyF2,
    numberF,
    paymentMethod,
    categoryType,
    approveTypes,
    typeOfTransport,
    typeFeeOnBehalf,
    revenueType,
    monthOfYear,
    yearNow,
    monthNow,
    typeFeeHeavy
}

